import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Reactmarkdown from 'react-markdown'
import moment from 'moment'
import { useIntl } from 'gatsby-plugin-intl'

import Alert from '../components/Alert/Alert'
import SEO from '../components/seo'
import { Container, Row, Col } from 'react-grid-system'
import Layout from '../components/Layouts/layout'
import { FaCalendar } from 'react-icons/fa'
import componentStyles from './article.module.scss'
// import SubPageNav from '../components/SubPageNav/SubPageNav'

const BlogTemplate = ({ data }) => {
  const intl = useIntl()

  return (
    <Layout
      header={
        <span className='bold'>{data.markdownRemark.frontmatter.title}</span>
      }
      breadcrumb={{
        text: 'The Home Front',
        path: '/blog/'
      }}
    >
      <SEO
        // description={intl.formatMessage({ id: 'metaTags.aboutDescription' })}
        lang='en'
        title={`The Home Front: ${data.markdownRemark.frontmatter.title}`}
        keywords={
          data.markdownRemark.frontmatter.keywords
            ? data.markdownRemark.frontmatter.keywords.join(', ')
            : null
        }
      />
      <Container>
        <Row>
          <Col>
            {intl.locale === 'fr' && (
              <Alert header='Attention'>
                <p lang='fr'>
                  Pour les services en français, veuillez contacter le Service
                  des licences et à la clientèle de l’Office de réglementation
                  de la construction des logements au 416-487-HCRA (4272) ou{' '}
                  <a href='mailto:info@hcraontario.ca'>info@hcraontario.ca</a>
                </p>
              </Alert>
            )}

            <div style={{ marginBottom: `1rem` }}>
              <div className={componentStyles.dateContainer}>
                <FaCalendar className={componentStyles.icon} />
                <span className='bold uppercase'>
                  {moment(data.markdownRemark.frontmatter.date).format(
                    'MMM Do, YYYY'
                  )}
                </span>
              </div>
            </div>
            <hr />

            {data.markdownRemark.frontmatter.articleImage && (
              <Img
                className={componentStyles.image}
                fluid={
                  data.markdownRemark.frontmatter.articleImage.childImageSharp
                    .fluid
                }
              />
            )}

            <Reactmarkdown source={data.markdownRemark.rawMarkdownBody} />
          </Col>
        </Row>
        {data.markdownRemark.frontmatter.articleIncontentImage && (
          <Row>
            <Col>
              <Img
                style={{
                  display: `block`,
                  maxWidth: `500px`,
                  margin: `2rem auto`
                }}
                fluid={
                  data.markdownRemark.frontmatter.articleIncontentImage
                    .childImageSharp.fluid
                }
              />
            </Col>
          </Row>
        )}
      </Container>
    </Layout>
  )
}

export default BlogTemplate

export const query = graphql`
  query BlogTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      rawMarkdownBody
      frontmatter {
        slug
        title
        date
        keywords
        imgFootnote
        image {
          absolutePath
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        articleImage {
          absolutePath
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        articleIncontentImage {
          absolutePath
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
    }
  }
`
